var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass: "PasswordRecover",
      on: {
        input: function($event) {
          return _vm.validation($event)
        },
        keyup: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
          ) {
            return null
          }
          return _vm.submit.apply(null, arguments)
        }
      }
    },
    [
      _c("div", { staticClass: "form-content" }, [
        _c(
          "div",
          { staticClass: "form-body" },
          [
            _c("h2", { staticClass: "title" }, [
              _vm._v(" " + _vm._s(_vm.$t("RECOVER.TITLE")) + " ")
            ]),
            _c(
              "BaseInput",
              _vm._b(
                {
                  scopedSlots: _vm._u([
                    {
                      key: "error",
                      fn: function() {
                        return [
                          _c("ValidationError", {
                            attrs: { errors: _vm.errors, name: _vm.field.name }
                          })
                        ]
                      },
                      proxy: true
                    }
                  ]),
                  model: {
                    value: _vm.field.value,
                    callback: function($$v) {
                      _vm.$set(_vm.field, "value", $$v)
                    },
                    expression: "field.value"
                  }
                },
                "BaseInput",
                _vm.field,
                false
              )
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "form-footer" },
          [
            _c(
              "BaseButton",
              {
                staticClass: "recover-btn",
                attrs: { variation: "primary", size: "l" },
                on: { click: _vm.submit }
              },
              [_vm._v(" " + _vm._s(_vm.$t("BUTTON.SEND")) + " ")]
            )
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }