var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "LoginView" },
    [
      _c("TransitionFade", [
        _c("div", { staticClass: "panel" }, [
          _c(
            "aside",
            [
              _c("div", {
                staticClass: "question",
                domProps: {
                  innerHTML: _vm._s(_vm.$t("LOGIN.TEXT.INTRODUCTION"))
                }
              }),
              _vm.$settings.COMPONENTS.REGISTER
                ? _c(
                    "BaseButton",
                    {
                      staticClass: "register",
                      attrs: { size: "l", variation: "secondary" },
                      on: {
                        click: function($event) {
                          return _vm.toggleView()
                        }
                      }
                    },
                    [
                      _c("TransitionSlide", { attrs: { side: "bottom" } }, [
                        _c("span", { key: _vm.toggleButtonName }, [
                          _vm._v(" " + _vm._s(_vm.toggleButtonName) + " ")
                        ])
                      ])
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "content" },
            [
              _c(
                "TransitionSlide",
                { attrs: { side: "left" } },
                [
                  _c(_vm.currentView, {
                    tag: "component",
                    on: {
                      "password-recover": _vm.openRecoverPasswordView,
                      "toggle-view": function($event) {
                        return _vm.toggleView()
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }