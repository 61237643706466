<template>
  <form
    class="PasswordRecover"
    @input="validation($event)"
    @keyup.enter="submit"
  >
    <div class="form-content">
      <div class="form-body">
        <h2 class="title">
          {{ $t('RECOVER.TITLE') }}
        </h2>

        <BaseInput
          v-model="field.value"
          v-bind="field"
        >
          <template #error>
            <ValidationError
              :errors="errors"
              :name="field.name"
            />
          </template>
        </BaseInput>
      </div>

      <div class="form-footer">
        <BaseButton
          variation="primary"
          size="l"
          class="recover-btn"
          @click="submit"
        >
          {{ $t('BUTTON.SEND') }}
        </BaseButton>
      </div>
    </div>
  </form>
</template>

<script>
import ValidationError from '@/components/common/ValidationError';
import ValidationMixin from '@/mixins/ValidationMixin';

export default {
  components: {
    ValidationError,
  },

  mixins: [ValidationMixin],

  data() {
    return {
      field: {
        type: 'text',
        name: 'email',
        placeholder: this.$t('LOGIN.NEWEMAIL.PLACEHOLDER'),
        error: this.$t('FORM.ERROR.EMAILTYPE'),
        required: true,
        value: undefined,
      },
    };
  },

  methods: {
    async submit() {
      try {
        const currentForm = document.forms[0];
        this.checkAllFields(currentForm);

        if (this.areErrors()) {
          throw new Error(this.$t('FORM.MESSAGE.ERROR.CHECKFIELDS'));
        }

        const formData = new FormData(currentForm);
        const data = { [this.field.name]: formData.get(this.field.name) };
        await this.send(data);

        this.$emit('toggle-view');
      } catch (error) {
        this.$toast.error(error.message);
      }
    },

    async send(data) {
      await this.$store.dispatch('authModule/passwordRecover', data);
    },
  },
};
</script>

<style lang="scss" scoped>
.PasswordRecover {
  .form-body {
    display: flex;
    flex-direction: column;
    gap: $spacing-s;

    .title{
      font-size: $font-size-2xl;
      letter-spacing: 1px;
      font-weight: 500;
      text-transform: uppercase;
      margin-bottom: $spacing-l;
      text-align: center;
    }
  }

  .form-footer {
    padding: $spacing-xl;

    .recover-btn {
      margin: auto;
    }
  }
}
</style>
