<template>
  <div class="PrivacyPolicyPanel">
    <h1 class="title">
      {{ $t('PRIVACY_POLICY.TITLE') }}
    </h1>

    <div
      class="content"
      v-html="$t('PRIVACY_POLICY.CONTENT.REGISTER')"
    />

    <div class="actions">
      <BaseButton
        variation="secondary"
        @click="$emit('refuse')"
      >
        {{ $t('BUTTON.REJECT') }}
      </BaseButton>

      <BaseButton @click="$emit('accept')">
        {{ $t('BUTTON.ACCEPT') }}
      </BaseButton>
    </div>
  </div>
</template>

<script>
export default {
};
</script>

<style lang="scss" scoped>
.PrivacyPolicyPanel{
  padding: $spacing-2xl $spacing-xl;
  display: flex;
  flex-direction: column;
  align-items: center;


  .title{
    font-size: $font-size-xl;
    font-weight: 500;
    text-transform: uppercase;
    border-bottom: 1px solid;
    align-self: stretch;
    padding: $spacing-2xs;
    margin-bottom: $spacing-m;
  }

  .content{
    text-align: justify;
    margin-bottom: $spacing-l;

    ::v-deep p{
      margin-bottom: $spacing-s;
    }
  }

  .actions{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    gap: $spacing-m;
    justify-items: center;
  }
}
</style>
