var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "PrivacyPolicyPanel" }, [
    _c("h1", { staticClass: "title" }, [
      _vm._v(" " + _vm._s(_vm.$t("PRIVACY_POLICY.TITLE")) + " ")
    ]),
    _c("div", {
      staticClass: "content",
      domProps: { innerHTML: _vm._s(_vm.$t("PRIVACY_POLICY.CONTENT.REGISTER")) }
    }),
    _c(
      "div",
      { staticClass: "actions" },
      [
        _c(
          "BaseButton",
          {
            attrs: { variation: "secondary" },
            on: {
              click: function($event) {
                return _vm.$emit("refuse")
              }
            }
          },
          [_vm._v(" " + _vm._s(_vm.$t("BUTTON.REJECT")) + " ")]
        ),
        _c(
          "BaseButton",
          {
            on: {
              click: function($event) {
                return _vm.$emit("accept")
              }
            }
          },
          [_vm._v(" " + _vm._s(_vm.$t("BUTTON.ACCEPT")) + " ")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }