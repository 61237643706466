<template>
  <form
    class="Login"
    @keyup.enter="submit"
  >
    <div class="form-body">
      <h2 class="title">
        {{ $t('TITLE.LOGIN') }}
      </h2>

      <BaseInput
        v-for="(field, i) in fields"
        :key="i"
        v-model="field.value"
        v-bind="field"
      >
        <template #error>
          <ValidationError
            :errors="errors"
            :name="field.name"
          />
        </template>
      </BaseInput>
    </div>

    <div class="form-footer">
      <div>
        <p
          class="recover"
          @click="$emit('password-recover')"
        >
          <BaseButton
            size="s"
            variation="tertiary"
          >
            {{ $t('REGISTER.FOROGOTPASSWORD.BUTTON') }}
          </BaseButton>
        </p>
      </div>

      <BaseButton
        class="login-btn"
        size="l"
        variation="primary"
        @click="submit"
      >
        {{ $t('LOGIN.TITLE') }}
      </BaseButton>
    </div>
  </form>
</template>

<script>
// import TransitionSlide from '@/transitions/TransitionSlide';
import ValidationError from '@/components/common/ValidationError';
import ValidationMixin from '@/mixins/ValidationMixin';

export default {
  components: {
    // TransitionSlide,
    ValidationError,
  },

  mixins: [ValidationMixin],

  data() {
    return {
      fields: [
        {
          type: 'text',
          name: 'email',
          icon: ['fas', 'envelope'],
          placeholder: 'Introduce tu usuario',
          error: 'Introduce un correo electrónico válido',
          required: true,
          value: undefined,
        },
        {
          type: 'password',
          name: 'password',
          icon: ['fas', 'key'],
          placeholder: 'Introduce tu contraseña',
          error: 'Introduce una contraseña',
          required: true,
          value: undefined,
        },
      ],
    };
  },

  async created() {
    this.$store.dispatch('localeModule/changeLocale', 'eu');
  },

  methods: {
    async submit() {
      try {
        const currentForm = document.forms[0];
        this.checkAllFields(currentForm);

        if (this.areErrors()) {
          throw new Error(this.$t('FORM.MESSAGE.ERROR.CHECKFIELDS'));
        }

        const formData = new FormData(currentForm);
        const data = this.fields.reduce((acc, field) => (Object.assign(acc, { [field.name]: formData.get(field.name) })), {});
        this.send(data);
      } catch (error) {
        this.$toast.error(error.message);
      }
    },

    async send(data) {
      await this.$store.dispatch('authModule/login', data);
    },
  },
};
</script>

<style lang="scss" scoped>
.Login{
  .form-body {
    display: flex;
    flex-direction: column;
    gap: $spacing-s;

    .title{
      font-size: $font-size-2xl;
      letter-spacing: 1px;
      font-weight: 500;
      text-transform: uppercase;
      margin-bottom: $spacing-l;
      text-align: center;
    }
  }

  .form-footer {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: $spacing-xl;

    gap: $spacing-s;

    .login-btn {
      text-transform: uppercase;
      font-weight: 500;
    }

    .recover {
      margin-bottom: $spacing-m;
      .BaseButton{
        color: $color-neutral-darker;
      }
    }
  }
}
</style>
