var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass: "Register",
      on: {
        keyup: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
          ) {
            return null
          }
          return _vm.submit.apply(null, arguments)
        },
        input: function($event) {
          return _vm.validation($event)
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "form-body" },
        [
          _c("h2", { staticClass: "title" }, [
            _vm._v(" " + _vm._s(_vm.$t("TITLE.CREATE.ACCOUNT")) + " ")
          ]),
          _c(
            "FormComponent",
            {
              attrs: {
                icon: ["fas", "building"],
                tag: "type",
                orientation: "row",
                errors: _vm.errors
              }
            },
            [
              _c(
                "select",
                {
                  attrs: { id: "type", name: "type", required: "" },
                  on: {
                    change: function($event) {
                      return _vm.onChange($event)
                    }
                  }
                },
                _vm._l(4, function(item) {
                  return _c(
                    "option",
                    { key: item, domProps: { value: item } },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("QUESTION.REGISTER.CHOICE." + item)) +
                          " "
                      )
                    ]
                  )
                }),
                0
              )
            ]
          ),
          _vm.type === "1"
            ? _c(
                "FormComponent",
                {
                  attrs: {
                    tag: "province",
                    icon: ["fas", "map"],
                    orientation: "row",
                    errors: _vm.errors
                  }
                },
                [
                  _c(
                    "select",
                    {
                      attrs: { id: "province", name: "province" },
                      on: {
                        change: function($event) {
                          return _vm.onChangeProvince($event)
                        }
                      }
                    },
                    _vm._l(3, function(prov, index) {
                      return _c(
                        "option",
                        { key: index, domProps: { value: prov } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("QUESTION.NA1.CHOICE." + prov)) +
                              " "
                          )
                        ]
                      )
                    }),
                    0
                  )
                ]
              )
            : _vm._e(),
          _vm.type === "1"
            ? _c(
                "FormComponent",
                {
                  attrs: {
                    icon: ["fas", "door-open"],
                    tag: "center",
                    orientation: "row",
                    errors: _vm.errors
                  }
                },
                [
                  _c(
                    "select",
                    {
                      attrs: { id: "center", name: "center", required: "" },
                      on: {
                        change: function($event) {
                          return _vm.onChangeCenter($event)
                        }
                      }
                    },
                    _vm._l(_vm.centerFilters, function(center) {
                      return _c(
                        "option",
                        { key: center.id, domProps: { value: center.id } },
                        [_vm._v(" " + _vm._s(center.nameEdit) + " ")]
                      )
                    }),
                    0
                  )
                ]
              )
            : _vm._e(),
          _vm.type === "1"
            ? _c(
                "FormComponent",
                {
                  attrs: {
                    icon: ["fas", "building"],
                    tag: "course",
                    orientation: "row",
                    errors: _vm.errors
                  }
                },
                [
                  _c(
                    "select",
                    {
                      class: _vm.error ? "select-error" : "",
                      attrs: { id: "course", name: "course", required: true }
                    },
                    _vm._l(_vm.coursesCenter, function(course) {
                      return _c(
                        "option",
                        { key: course.id, domProps: { value: course.id } },
                        [_vm._v(" " + _vm._s(course.name) + " ")]
                      )
                    }),
                    0
                  )
                ]
              )
            : _vm._e(),
          _vm.type !== "1"
            ? _c(
                "FormComponent",
                {
                  attrs: {
                    icon: ["fas", "user"],
                    tag: "nameType",
                    orientation: "row",
                    errors: _vm.errors
                  }
                },
                [
                  _c("LoginInput", {
                    attrs: {
                      type: "text",
                      tag: "nameType",
                      placeholder:
                        _vm.$t("PROFILE.NAME.LABEL") +
                        " " +
                        _vm.$t("QUESTION.REGISTER.CHOICE." + _vm.type),
                      title: _vm.$t("FORM.ERROR.EMPTYFIELD"),
                      required: true
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _c(
            "FormComponent",
            {
              attrs: {
                icon: ["fas", "user"],
                tag: "name",
                orientation: "row",
                errors: _vm.errors
              }
            },
            [
              _c("LoginInput", {
                attrs: {
                  type: "text",
                  tag: "name",
                  placeholder: _vm.$t("LOGIN.NAME.PLACEHOLDER"),
                  title: _vm.$t("FORM.ERROR.EMPTYFIELD"),
                  required: true
                }
              })
            ],
            1
          ),
          _c(
            "FormComponent",
            {
              attrs: {
                icon: ["fas", "user"],
                tag: "surname",
                orientation: "row",
                errors: _vm.errors
              }
            },
            [
              _c("LoginInput", {
                attrs: {
                  type: "text",
                  tag: "surname",
                  placeholder: _vm.$t("LOGIN.SURNAME.PLACEHOLDER"),
                  title: _vm.$t("FORM.ERROR.EMPTYFIELD"),
                  required: true
                }
              })
            ],
            1
          ),
          _c(
            "FormComponent",
            {
              attrs: {
                icon: ["fas", "envelope"],
                tag: "email",
                orientation: "row",
                errors: _vm.errors
              }
            },
            [
              _c("LoginInput", {
                attrs: {
                  type: "text",
                  tag: "email",
                  placeholder: _vm.$t("LOGIN.NEWEMAIL.PLACEHOLDER"),
                  pattern: "^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$",
                  title: _vm.$t("FORM.ERROR.EMAILTYPE"),
                  required: true
                }
              })
            ],
            1
          ),
          _c(
            "FormComponent",
            {
              attrs: {
                icon: ["fas", "lock"],
                tag: "password",
                orientation: "row",
                errors: _vm.errors
              }
            },
            [
              _c("LoginInput", {
                attrs: {
                  type: "password",
                  tag: "password",
                  placeholder: _vm.$t("LOGIN.PASSWORD.PLACEHOLDER"),
                  pattern: ".{8,}",
                  title: _vm.$t("FORM.ERROR.MINCHARACTERS", { number: 8 }),
                  required: true
                }
              })
            ],
            1
          ),
          _c(
            "FormComponent",
            {
              attrs: {
                icon: ["fas", "lock"],
                tag: "reppassword",
                orientation: "row",
                errors: _vm.errors
              }
            },
            [
              _c("LoginInput", {
                attrs: {
                  type: "password",
                  tag: "reppassword",
                  placeholder: _vm.$t("LOGIN.REPEATPASSWORD.PLACEHOLDER"),
                  pattern: ".{8,}",
                  title: _vm.$t("FORM.ERROR.MINCHARACTERS", { number: 8 }),
                  required: true
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "privacy-policy" },
            [
              _c(
                "BaseSwitcher",
                _vm._b(
                  {
                    key: "OPT" + _vm.privacyPolicyAccepted,
                    nativeOn: {
                      click: function($event) {
                        !_vm.privacyPolicyAccepted
                          ? _vm.openPrivacyPolicy()
                          : null
                      }
                    },
                    model: {
                      value: _vm.privacyPolicyAccepted,
                      callback: function($$v) {
                        _vm.privacyPolicyAccepted = $$v
                      },
                      expression: "privacyPolicyAccepted"
                    }
                  },
                  "BaseSwitcher",
                  _vm.privacyPolicy,
                  false
                )
              ),
              _c("p", {
                staticClass: "policy-read",
                domProps: {
                  innerHTML: _vm._s(_vm.$t("TITLE.READ.POLITICIES"))
                },
                on: { click: _vm.openPrivacyPolicy }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "BaseModal",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isModalVisible,
              expression: "isModalVisible"
            }
          ],
          attrs: { "header-visible": false, size: "m" },
          on: { close: _vm.closePrivacyPolicy }
        },
        [
          _c("PrivacyPolicyPanel", {
            on: {
              refuse: _vm.closePrivacyPolicy,
              accept: _vm.acceptPrivacyPolicy
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "form-footer" },
        [
          _c(
            "BaseButton",
            {
              staticClass: "register-btn",
              attrs: { size: "l", variation: "primary" },
              on: { click: _vm.submit }
            },
            [_vm._v(" " + _vm._s(_vm.$t("BUTTON.SEND")) + " ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }