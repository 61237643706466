var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass: "Login",
      on: {
        keyup: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
          ) {
            return null
          }
          return _vm.submit.apply(null, arguments)
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "form-body" },
        [
          _c("h2", { staticClass: "title" }, [
            _vm._v(" " + _vm._s(_vm.$t("TITLE.LOGIN")) + " ")
          ]),
          _vm._l(_vm.fields, function(field, i) {
            return _c(
              "BaseInput",
              _vm._b(
                {
                  key: i,
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "error",
                        fn: function() {
                          return [
                            _c("ValidationError", {
                              attrs: { errors: _vm.errors, name: field.name }
                            })
                          ]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    true
                  ),
                  model: {
                    value: field.value,
                    callback: function($$v) {
                      _vm.$set(field, "value", $$v)
                    },
                    expression: "field.value"
                  }
                },
                "BaseInput",
                field,
                false
              )
            )
          })
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "form-footer" },
        [
          _c("div", [
            _c(
              "p",
              {
                staticClass: "recover",
                on: {
                  click: function($event) {
                    return _vm.$emit("password-recover")
                  }
                }
              },
              [
                _c(
                  "BaseButton",
                  { attrs: { size: "s", variation: "tertiary" } },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("REGISTER.FOROGOTPASSWORD.BUTTON")) +
                        " "
                    )
                  ]
                )
              ],
              1
            )
          ]),
          _c(
            "BaseButton",
            {
              staticClass: "login-btn",
              attrs: { size: "l", variation: "primary" },
              on: { click: _vm.submit }
            },
            [_vm._v(" " + _vm._s(_vm.$t("LOGIN.TITLE")) + " ")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }