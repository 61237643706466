<template>
  <form
    class="Register"
    @keyup.enter="submit"
    @input="validation($event)"
  >
    <div class="form-body">
      <h2 class="title">
        {{ $t('TITLE.CREATE.ACCOUNT') }}
      </h2>

      <FormComponent
        :icon="['fas', 'building']"
        tag="type"
        orientation="row"
        :errors="errors"
      >
        <select
          id="type"
          name="type"
          required
          @change="onChange($event)"
        >
          <!-- <option>
            {{ $t('SELECT.PLACEHOLDER') }}
          </option> -->
          <option
            v-for="item in 4"
            :key="item"
            :value="item"
          >
            {{ $t(`QUESTION.REGISTER.CHOICE.${item}`) }}
          </option>
        </select>
      </FormComponent>

      <FormComponent
        v-if="type ==='1'"
        tag="province"
        :icon="['fas', 'map']"
        orientation="row"
        :errors="errors"
      >
        <select
          id="province"
          name="province"
          @change="onChangeProvince($event)"
        >
          <option
            v-for="(prov, index) in 3"
            :key="index"
            :value="prov"
          >
            {{ $t(`QUESTION.NA1.CHOICE.${prov}`) }}
          </option>
        </select>
      </FormComponent>

      <FormComponent
        v-if="type ==='1'"
        :icon="['fas', 'door-open']"
        tag="center"
        orientation="row"
        :errors="errors"
      >
        <select
          id="center"
          name="center"
          required
          @change="onChangeCenter($event)"
        >
          <!-- <option
            selected
            disabled
            value="null"
            hidden
          >
            {{ $t('SELECT.PLACEHOLDER') }}
          </option> -->
          <option
            v-for="center in centerFilters"
            :key="center.id"
            :value="center.id"
          >
            {{ center.nameEdit }}
          </option>
        </select>
      </FormComponent>

      <FormComponent
        v-if="type ==='1'"
        :icon="['fas', 'building']"
        tag="course"
        orientation="row"
        :errors="errors"
      >
        <select
          id="course"
          name="course"
          :required="true"
          :class="error ? 'select-error' : ''"
        >
          <!--  <option
            selected
            value=""
          >
            {{ $t('SELECT.PLACEHOLDER.COURSE') }}
          </option> -->
          <option
            v-for="course in coursesCenter"
            :key="course.id"
            :value="course.id"
          >
            {{ course.name }}
          </option>
        </select>
      </FormComponent>

      <FormComponent
        v-if="type!=='1'"
        :icon="['fas', 'user']"
        tag="nameType"
        orientation="row"
        :errors="errors"
      >
        <LoginInput
          type="text"
          tag="nameType"
          :placeholder="$t('PROFILE.NAME.LABEL') + ' ' + $t(`QUESTION.REGISTER.CHOICE.${type}`)"
          :title="$t('FORM.ERROR.EMPTYFIELD')"
          :required="true"
        />
      </FormComponent>

      <FormComponent
        :icon="['fas', 'user']"
        tag="name"
        orientation="row"
        :errors="errors"
      >
        <LoginInput
          type="text"
          tag="name"
          :placeholder="$t('LOGIN.NAME.PLACEHOLDER')"
          :title="$t('FORM.ERROR.EMPTYFIELD')"
          :required="true"
        />
      </FormComponent>

      <FormComponent
        :icon="['fas', 'user']"
        tag="surname"
        orientation="row"
        :errors="errors"
      >
        <LoginInput
          type="text"
          tag="surname"
          :placeholder="$t('LOGIN.SURNAME.PLACEHOLDER')"
          :title="$t('FORM.ERROR.EMPTYFIELD')"
          :required="true"
        />
      </FormComponent>

      <FormComponent
        :icon="['fas', 'envelope']"
        tag="email"
        orientation="row"
        :errors="errors"
      >
        <LoginInput
          type="text"
          tag="email"
          :placeholder="$t('LOGIN.NEWEMAIL.PLACEHOLDER')"
          pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
          :title="$t('FORM.ERROR.EMAILTYPE')"
          :required="true"
        />
      </FormComponent>

      <FormComponent
        :icon="['fas', 'lock']"
        tag="password"
        orientation="row"
        :errors="errors"
      >
        <LoginInput
          type="password"
          tag="password"
          :placeholder="$t('LOGIN.PASSWORD.PLACEHOLDER')"
          pattern=".{8,}"
          :title="$t('FORM.ERROR.MINCHARACTERS', { number: 8 })"
          :required="true"
        />
      </FormComponent>

      <FormComponent
        :icon="['fas', 'lock']"
        tag="reppassword"
        orientation="row"
        :errors="errors"
      >
        <LoginInput
          type="password"
          tag="reppassword"
          :placeholder="$t('LOGIN.REPEATPASSWORD.PLACEHOLDER')"
          pattern=".{8,}"
          :title="$t('FORM.ERROR.MINCHARACTERS', { number: 8 })"
          :required="true"
        />
      </FormComponent>

      <div class="privacy-policy">
        <BaseSwitcher
          :key="`OPT${privacyPolicyAccepted}`"
          v-model="privacyPolicyAccepted"
          v-bind="privacyPolicy"
          @click.native="!privacyPolicyAccepted ? openPrivacyPolicy() : null"
        />
        <p
          class="policy-read"
          @click="openPrivacyPolicy"
          v-html="$t('TITLE.READ.POLITICIES')"
        />
        <!--  <p>
          He leído y acepto las <b @click="openPrivacyPolicy">condiciones de servicio</b> y la <b @click="openPrivacyPolicy">política de privacidad</b>
        </p> -->
      </div>
    </div>

    <BaseModal
      v-show="isModalVisible"
      :header-visible="false"
      size="m"
      @close="closePrivacyPolicy"
    >
      <PrivacyPolicyPanel
        @refuse="closePrivacyPolicy"
        @accept="acceptPrivacyPolicy"
      />
    </BaseModal>

    <div class="form-footer">
      <BaseButton
        class="register-btn"
        size="l"
        variation="primary"
        @click="submit"
      >
        {{ $t('BUTTON.SEND') }}
      </BaseButton>
    </div>
  </form>
</template>

<script>
import { get } from 'vuex-pathify';

import FormComponent from '@/components/common/FormComponent';
import ValidationMixin from '@/mixins/ValidationMixin';
import PrivacyPolicyPanel from '@/components/common/PrivacyPolicyPanel';
import LoginInput from '@/components/Login/LoginInput';


export default {
  components: {
    PrivacyPolicyPanel,
    FormComponent,
    LoginInput,
  },

  mixins: [ValidationMixin],

  data() {
    return {
      user: undefined,
      password: undefined,
      type: '1',
      idCenter: '',
      courses: undefined,
      error: false,
      province: 1,
      typeRegister:
      {
        question: 'REGISTER',
        choices: [1, 2, 3, 4],
      },

      privacyPolicy: {
        name: 'policy',
      },
      privacyPolicyAccepted: false,
      isModalVisible: false,
      centerFilters: undefined,
    };
  },

  computed: {
    ...get('adminModule', ['getCenterwhithcourse', 'getCoursescenter', 'getCenters', 'getCourses']),

    questionAttributes() {
      return Object.assign({}, this.typeRegister, { name: 'REGISTER', disabled: true });
    },


    coursesCenter() {
      return this.getCourses();
    },
  },

  async created() {
    await this.$store.dispatch('adminModule/fetchCenters');
    const center = this.getCenters();
    await this.$store.dispatch('adminModule/fetchCourses');
    this.centers();
    this.idCenter = center[0].id;
    this.centerFilters = this.centers();
  },

  methods: {
    openPrivacyPolicy() {
      this.isModalVisible = true;
      this.privacyPolicyAccepted = false;
    },

    acceptPrivacyPolicy() {
      this.isModalVisible = false;
      this.privacyPolicyAccepted = true;
    },

    closePrivacyPolicy() {
      this.privacyPolicyAccepted = false;
      this.isModalVisible = false;
    },

    onChange(event) {
      this.type = event.target.value;
    },

    onChangeCenter(event) {
      this.idCenter = event.target.value;
    },

    async onChangeProvince(event) {
      // eslint-disable-next-line radix
      this.province = parseInt(event.target.value);

      const centers = this.getCenters();

      const filterCenters = centers.filter(center => center.province === this.province);
      this.centerFilters = filterCenters;

      return this.filterCenters?.sort((a, b) => {
        const nameA = a.nameEdit.toUpperCase();
        const nameB = b.nameEdit.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        return 0;
      });
      // this.centers();
    },

    centers() {
      const centers = this.getCenters();
      const filterCenters = centers?.filter(center => center.province === this.province);

      return filterCenters?.sort((a, b) => {
        const nameA = a.nameEdit.toUpperCase();
        const nameB = b.nameEdit.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        return 0;
      });
    },

    submit() {
      const currentForm = document.forms[0];
      const formData = new FormData(currentForm);
      const name = formData.get('form-name');
      const surname = formData.get('form-surname');
      const email = formData.get('form-email');
      const password = formData.get('form-password');
      const reppassword = formData.get('form-reppassword');
      const type = formData.get('type');
      const center = formData.get('center');
      const course = formData.get('course');
      const nameType = formData.get('form-nameType');

      this.checkAllFields(currentForm);

      if (this.areErrors()) {
        this.$toast.error(this.$t('FORM.MESSAGE.ERROR.CHECKFIELDS'));
      } else if (password !== reppassword) {
        this.$toast.error(this.$t('FORM.MESSAGE.ERROR.PASSWORDSMATCH'));
        const reppasswordField = currentForm.querySelector('[name=form-reppassword]');
        this.forceErrorElement(reppasswordField, this.$t('FORM.ERROR.REPEATPASSWORD'));
      } else if (type === '1' && course === '') {
        this.$toast.error(this.$t('FORM.MESSAGE.ERROR.CHECKFIELDS'));
        this.error = true;
      } else if (!this.privacyPolicyAccepted) {
        this.$toast.error(this.$t('FORM.MESSAGE.ERROR.PRIVACY_POLICY'));
      } else {
        const data = {
          firstName: name, lastName: surname, email, password, passwordRepeat: reppassword, type, center, course, nameType,
        };
        this.send(data);
      }
    },
    getField(name) {
      return this.fields.find(field => field.name === name)?.value;
    },

    async send(data) {
      await this.$store.dispatch('authModule/register', data);
      this.$emit('toggle-view', 'login');
    },
  },
};
</script>

<style lang="scss" scoped>
.Register {
  .form-body {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: $spacing-s;

    select {
      width: 95%;
      margin-left: 1.5rem;
      height: 3rem;
      border: 0;
      border-bottom: solid 1px;
      position: relative;
      margin-top: -2rem;
      margin-bottom: 1rem;
      font-size: $font-size-m;
      background: white;
      text-transform: uppercase;

      &::placeholder {
        color: var($color-neutral-light);
      }
    }

    .select-error {
      border-bottom: solid 1px red;
    }

    .title {
      font-size: $font-size-2xl;
      letter-spacing: 1px;
      font-weight: 500;
      text-transform: uppercase;
      margin-bottom: $spacing-l;
      text-align: center;
    }
  }

  .privacy-policy {
    padding: $spacing-s $spacing-m;
    box-shadow: $shadow-elevation-1;
    background: $color-neutral-lightest;
    display: grid;
    grid-auto-flow: column;
    justify-content: space-between;
    font-size: $font-size-s;
    margin-top: $spacing-m;

    b {
      cursor: pointer;
      font-weight: 500;
      color: $color-primary-dark;
    }
  }

  .policy-read{
    color: $color-primary;
    cursor: pointer;
    font-weight: 600;
  }

  .form-footer {
    margin-top: $spacing-xl;

    .register-btn {
      margin: auto;
    }
  }
}
</style>
