<template>
  <div class="LoginView">
    <TransitionFade>
      <div class="panel">
        <aside>
          <!--   <h1 class="title">
            {{ $t('TITLE.WELCOME') }}
          </h1> -->

          <!--  <img
            :src="require(`@/assets/images/logo_aztertu_blanco.png`)"
            alt="logo"
          > -->
          <!-- <p class="question">
            <span>¿Qué es el programa AZTERTU?</span>
            Se trata de concienciar y fomentar el respeto hacia el medioambiente, haciendo hincapié en el cuidado de nuestras costas y ríos.
          </p> -->
          <div
            class="question"
            v-html="$t('LOGIN.TEXT.INTRODUCTION')"
          />

          <BaseButton
            v-if="$settings.COMPONENTS.REGISTER"
            class="register"
            size="l"
            variation="secondary"
            @click="toggleView()"
          >
            <TransitionSlide side="bottom">
              <span :key="toggleButtonName">
                {{ toggleButtonName }}
              </span>
            </TransitionSlide>
          </BaseButton>
        </aside>

        <div class="content">
          <TransitionSlide side="left">
            <component
              :is="currentView"
              @password-recover="openRecoverPasswordView"
              @toggle-view="toggleView()"
            />
          </TransitionSlide>
        </div>
      </div>
    </TransitionFade>
  </div>
</template>


<script>
import TransitionSlide from '@/transitions/TransitionSlide';
import TransitionFade from '@/transitions/TransitionFade';
import Login from '@/components/Login/Login';
import Register from '@/components/Login/Register';
import PasswordRecover from '@/components/Login/PasswordRecover';

const VIEWS = ['Login', 'Register'];

export default {
  components: {
    TransitionSlide,
    TransitionFade,
    Login,
    Register,
    PasswordRecover,
  },

  data() {
    return {
      currentView: undefined,
    };
  },

  computed: {
    toggleButtonName() {
      return (this.currentView === 'Login') ? this.$t('BUTTON.REGISTER.TITLE') : this.$t('BUTTON.LOGIN.TITLE');
    },
  },

  created() {
    this.currentView = VIEWS[0];
  },

  methods: {
    async toggleView() {
      this.currentView = VIEWS.find(view => view !== this.currentView);
    },

    openRecoverPasswordView() {
      this.currentView = 'PasswordRecover';
    },
  },
};
</script>

<style lang="scss" scoped>
.LoginView {
  display: flex;
  align-content: center;
  justify-content: center;
  padding-top: $theHeaderSpacing;
  width: 100%;
  padding-bottom: $spacing-2xl;
  padding-top: $spacing-3xl;


  background-image: url('~@/assets/images/ibaialde.jpg');
  background-size: cover;
  background-position: center;
  background-color: #c6da17;
  background-blend-mode: luminosity;


  .panel {
    max-width: 1000px;
    width: 180%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    box-shadow: $shadow-elevation-4;

    .question{
      padding: 1rem;
      text-align: justify;
       p{
       color:red !important;
     }
    }

    img{
      width: 40%;
      margin-bottom: $spacing-l;
    }

    @media #{$breakpoint-lg-max} {
    grid-template-columns: repeat(1, 1fr);
  }

    // border-radius: 5px;

    & > * {
      padding: $spacing-4xl $spacing-2xl;
      display: grid;
      align-content: center;
    }

    aside{
      background: linear-gradient(45deg, $color-primary, $color-primary-darker);
      color: #fff;

      .title{
        font-size: $font-size-2xl;
        letter-spacing: 1px;
        font-weight: 500;
        text-transform: uppercase;
        margin-bottom: $spacing-l;
        text-align: center;
      }

      p {
        text-align: center;
        margin-bottom: $spacing-l;
      }

      .register{
        margin: auto;
      }
    }
  }

  .content{
    background: #fff;
  }
}
</style>
